import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { Formik, Form, Field } from "formik";
import { TextField, Select, RadioGroup, CheckboxWithLabel, Checkbox } from "formik-mui";
import { FormControlLabel, Radio } from "@mui/material";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Button } from "@mui/material";
import { getCookie } from "cookies-next";

import mainTheme from "../mainTheme";

import { sendWebhook } from "../../lib/webhook";

import styles from "../../styles/Form.module.css";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  first_name: yup.string("Enter your first name").required("First Name is required"),
  last_name: yup.string("Enter your last name").required("Last Name is required"),
});

const DemoForm = ({ listId, formName, successUrl, successMsg, buttonText, buttonColor, fieldList }) => {
  successUrl = successUrl || "";
  successMsg = successMsg || (
    <div>
      <strong>WE'RE EXCITED TO CONNECT WITH YOU!</strong>
      <br />
    </div>
  );
  buttonText = buttonText || "Submit";
  buttonColor = buttonColor || "secondary";
  fieldList = fieldList || ["first_name", "last_name", "email", "phone"]; // Options include: roll, company, mls, interests, qualifying, crm_interests, markets, needs, company_details, idx_options

  const [formState, setFormState] = useState("incomplete");
  const [crmFlavor, setCrmFlavor] = useState("");
  const router = useRouter();
  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  if (formState === "success") {
    if (crmFlavor == "IXACT") {
      successUrl = "thankyou-ixact";
    }

    if (successUrl.length > 4) {
      router.push(successUrl);
    } else {
      return <div>{successMsg}</div>;
    }
  } else if (formState === "error") {
    return (
      <div style={{ maxWidth: "400px", margin: "24px auto" }}>
        <div color="error">An error occurred.</div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          company: "",
          email: "",
          phone: "",
          mls_number: "",
          title: "",
          website: "",
          role: "",
          roleOther: "",
          interests_other: [],
          city: "",
          state: "",
          num_agents: "",
          bl_host: "",
          office_party: "",
          interests: [],
          brunch_interests: [],
          masterclass_interests: [],
          homesmart_interests: [],
          homesmart_interests_other: [],
          solutions: [],
          schedule_demo: "",
          existing_client: "",
          current_solutions: "",
          form: formName,
          list: listId,
        }}
        validationSchema={() =>
          yup.object().shape({
            firstname: yup.string().required("First name is required"),
            lastname: yup.string().required("Last name is required"),
            company: yup.string(),
            email: yup.string().email("Invalid email address").required("Email is required"),
            phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
            role: yup.string(),
            roleOther: yup.string(),
            interests: yup.array(),
            solutions: yup.array(),
            homesmart_interests: yup.array(),
            masterclass_interests: yup.array(),
            city: yup.string(),
            state: yup.string(),
            num_agents: yup.string(),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          var meeting_request = "";
          var theseNotes = values.notes ? values.notes : "";
          Object.keys(values).forEach(function (key, index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object
            if (key == "schedule_demo") {
              if (values.schedule_demo) {
                theseNotes += " I want to schedule a demo, ";
              }
            }
            if (key == "existing_client") {
              if (values.existing_client) {
                theseNotes += " I am an existing client, ";
              }
            }
            if (key == "current_solutions") {
              if (values.current_solutions) {
                theseNotes += " I am currently using " + values.current_solutions + ", ";
              }
            }
            if (key == "solutions") {
              if (values.solutions) {
                theseNotes += values.solutions.join(", ");
              }
            }
            if (key == "interests") {
              if (values.interests) {
                theseNotes += values.interests.join(", ");
              }
            }
            if (key == "brunch_interests") {
              if (values.brunch_interests) {
                theseNotes += values.brunch_interests.join(", ");
              }
            }
            if (key == "masterclass_interests") {
              if (values.masterclass_interests) {
                theseNotes += values.masterclass_interests.join(", ");
              }
            }

            // Special handling for CRM interestes
            if (key == "crm_interests") {
              if (values.crm_interests && values.crm_interests == "IXACT") {
                values.list = 147983;
                values.form = "es2_crm_ixact";
                setCrmFlavor("IXACT");
              }
              if (values.crm_interests && values.crm_interests == "Elevate") {
                values.list = 147984;
                values.form = "es2_crm_elevate";
                setCrmFlavor("Elevate");
              }
            }
            // Special handling for B&L Host
            if (key == "bl_host") {
              if (values.bl_host && values.bl_host == "Yes") {
                values.form = "es2_nar_bl_host";
              }
            }
            if (key == "market1") {
              if (values.market1) {
                theseNotes += " Markets:" + values.market1 + "," + values.market2 + "," + values.market3 + " ";
              }
            }
            if (key == "title") {
              if (values.title) {
                theseNotes += "  Title: " + values.title;
              }
            }
            if (key == "website") {
              if (values.title) {
                theseNotes += "  Website: " + values.title;
              }
            }
          });

          let cookies = getCookie("elmlead");
          if (cookies && cookies.length > 1) {
            cookies = JSON.parse(cookies);
          }

          const body = {
            ...values,
            notes: theseNotes,
            role: values.role === "Other" ? values.roleOther : values.role, // Just in case the user had typed in roleOther then changed their role to something else
            interests: values.interests.includes("Other")
              ? values.interests_other.concat(values.interests)
              : values.interests, // Just in case the user had typed in interests_other then changed their interests to something else
            homesmart_interests: values.homesmart_interests.includes("Other")
              ? values.homesmart_interests_other.concat(values.homesmart_interests)
              : values.homesmart_interests, // Just in case the user had typed in homesmart_interests_other then changed their homesmart_interests to something else
            utm_campaign: cookies && cookies.utm_campaign ? cookies.utm_campaign : "",
            utm_source: cookies && cookies.utm_source ? cookies.utm_source : "",
            utm_medium: cookies && cookies.utm_medium ? cookies.utm_medium : "",
            utm_term: cookies && cookies.utm_term ? cookies.utm_term : "",
            utm_content: cookies && cookies.utm_content ? cookies.utm_content : "",
          };
          return fetch("https://hooks.zapier.com/hooks/catch/4496703/327m58e/", {
            method: "POST",
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((res) => {
              if (res.status === "success") {
                setFormState("success");
                dataLayer.push({ event: "form-success" });

                if (window.fbq) {
                  window.fbq("track", "Lead");
                }
                if (window.gtag) {
                  window.gtag("event", "conversion", {
                    send_to: "AW-980447970/2_aHCOnsjo8BEOLlwdMD",
                  });
                }
                if (window.ga) {
                  window.ga("send", "event", "form", "form_completed", "get-started");
                }
              } else {
                setFormState("error");
              }
            })
            .catch((err) => {
              setFormState("error");
            });
        }}
        render={({ values, isSubmitting, handleBlur, handleChange }) => (
          <Form noValidate>
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Field
                        id="firstname"
                        name="firstname"
                        label="first name"
                        component={TextField}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Field
                        id="lastname"
                        name="lastname"
                        label="last name"
                        component={TextField}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Field id="email" name="email" label="email" component={TextField} size="small" fullWidth />
                </Grid>
                {fieldList.includes("phone") && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Field
                      id="phone"
                      name="phone"
                      label="phone"
                      component={TextField}
                      size="small"
                      type="tel"
                      fullWidth
                    />
                  </Grid>
                )}
                {fieldList.includes("company") && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Field id="company" name="company" label="company" component={TextField} size="small" fullWidth />
                  </Grid>
                )}
                {fieldList.includes("mls") && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Field
                      id="mls_number"
                      name="mls_number"
                      label="MLS # (optional)"
                      component={TextField}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}
                {fieldList.includes("company_details") && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field id="title" name="title" label="title" component={TextField} size="small" fullWidth />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field id="website" name="website" label="website" component={TextField} size="small" fullWidth />
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                        Tell us about your company:
                      </span>
                      <Field id="notes" name="notes" label="" component={TextField} size="small" fullWidth />
                    </Grid>
                  </>
                )}
                {fieldList.includes("roll") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>I am a(n):</span>
                    <Field component={RadioGroup} name="role">
                      <FormControlLabel
                        value="Agent"
                        control={<Radio disabled={isSubmitting} />}
                        label="Agent"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Team"
                        control={<Radio disabled={isSubmitting} />}
                        label="Team"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Broker"
                        control={<Radio disabled={isSubmitting} />}
                        label="Broker/Owner"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Partner"
                        control={<Radio disabled={isSubmitting} />}
                        label="Developer Partner"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio disabled={isSubmitting} />}
                        label="Other (please specify)*"
                        disabled={isSubmitting}
                      />
                    </Field>

                    {values.role === "Other" && (
                      <Field
                        id="roleOther"
                        name="roleOther"
                        label="Other"
                        component={TextField}
                        size="small"
                        autoFocus
                      />
                    )}
                  </Grid>
                )}
                {fieldList.includes("office_party") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      Enter me to win the $1000 holiday office party!
                    </span>
                    <Field component={RadioGroup} name="office_party">
                      <FormControlLabel
                        value="Yes"
                        control={<Radio disabled={isSubmitting} />}
                        label="Yes"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio disabled={isSubmitting} />}
                        label="No"
                        disabled={isSubmitting}
                      />
                    </Field>
                  </Grid>
                )}
                {fieldList.includes("bl_host") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      I’d like to talk to someone about hosting a Brunch & Learn experience at my office
                    </span>
                    <Field component={RadioGroup} name="bl_host">
                      <FormControlLabel
                        value="Yes"
                        control={<Radio disabled={isSubmitting} />}
                        label="Yes"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio disabled={isSubmitting} />}
                        label="No"
                        disabled={isSubmitting}
                      />
                    </Field>
                  </Grid>
                )}
                {fieldList.includes("num_agents") && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field
                        id="num_agents"
                        name="num_agents"
                        label="# of Agents"
                        component={TextField}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                {fieldList.includes("city") && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field id="city" name="city" label="City" component={TextField} size="small" fullWidth />
                    </Grid>
                  </>
                )}
                {fieldList.includes("state") && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field id="state" name="state" label="State" component={TextField} size="small" fullWidth />
                    </Grid>
                  </>
                )}
                {fieldList.includes("crm_interests") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      I am interested in: (required)
                    </span>
                    <Field component={RadioGroup} name="crm_interests">
                      <FormControlLabel
                        value="IXACT"
                        control={<Radio disabled={isSubmitting} />}
                        label="IXACT Contact&reg; CRM - Sphere of Influence Marketing"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Elevate"
                        control={<Radio disabled={isSubmitting} />}
                        label="Elevate CRM&reg; - Prospecting Done For You Marketing"
                        disabled={isSubmitting}
                      />
                    </Field>
                  </Grid>
                )}
                {fieldList.includes("interests") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      I am interested in: (select all that apply)
                    </span>
                    <Grid container spacing={1}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="CRM Solutions"
                          Label={{ label: "CRM Solutions" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Lead Generation"
                          Label={{ label: "Lead Generation" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Concierge Services"
                          Label={{ label: "Concierge Services" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="MLS Data Services"
                          Label={{ label: "MLS Data Services" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Recruitment &amp; Business Growth"
                          Label={{ label: "Recruitment & Business Growth" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Transaction Mgt."
                          Label={{ label: "Transaction Mgt." }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="IDX Websites"
                          Label={{ label: "IDX Websites" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Social Media Marketing"
                          Label={{ label: "Social Media Marketing" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Marketing Automation"
                          Label={{ label: "Marketing Automation" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Telecommunications"
                          Label={{ label: "Telecommunications" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="interests"
                          value="Other"
                          Label={{ label: "Other (please specify)" }}
                          size="small"
                        />
                        {values.interests.includes("Other") && (
                          <Field
                            id="interests_other"
                            name="interests_other"
                            label="Other"
                            component={TextField}
                            size="small"
                            autoFocus
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {fieldList.includes("brunch_interests") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      I am interested in: (select all that apply)
                    </span>
                    <Grid container spacing={1}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="brunch_interests"
                          value="Social Media Masterclass"
                          Label={{ label: "Social Media Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="brunch_interests"
                          value="Lead Generation Masterclass"
                          Label={{ label: "Lead Generation Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="brunch_interests"
                          value="Repeat/Referral Masterclass"
                          Label={{ label: "Repeat/Referral Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="brunch_interests"
                          value="Listings Masterclass"
                          Label={{ label: "Listings Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="brunch_interests"
                          value="Lead Nurturing/Lead Conversion Masterclass"
                          Label={{ label: "Lead Nurturing/Lead Conversion Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="brunch_interests"
                          value="General Technology/Social Platform Training"
                          Label={{ label: "General Technology/Social Platform Training" }}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {fieldList.includes("masterclass_interests") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      I am interested in: (select all that apply)
                    </span>
                    <Grid container spacing={1}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="masterclass_interests"
                          value="Social Media Masterclass"
                          Label={{ label: "Social Media Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="masterclass_interests"
                          value="Lead Generation Masterclass"
                          Label={{ label: "Lead Generation Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="masterclass_interests"
                          value="Listings Masterclass"
                          Label={{ label: "Listings Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="masterclass_interests"
                          value="Referral Masterclass"
                          Label={{ label: "Referral Masterclass" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="masterclass_interests"
                          value="Online Digital Marketing Training"
                          Label={{ label: "Online Digital Marketing Training" }}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {fieldList.includes("homesmart_interests") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      I am interested in: (select all that apply)
                    </span>
                    <Grid container spacing={1}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="CRM Solutions"
                          Label={{ label: "CRM Solutions" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Lead Generation"
                          Label={{ label: "Lead Generation" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Concierge Services"
                          Label={{ label: "Concierge Services" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="MLS Data Services"
                          Label={{ label: "MLS Data Services" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Recruitment &amp; Business Growth"
                          Label={{ label: "Recruitment & Business Growth" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Transaction Mgt."
                          Label={{ label: "Transaction Mgt." }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="IDX Websites"
                          Label={{ label: "IDX Websites" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Social Media Marketing"
                          Label={{ label: "Social Media Marketing" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Marketing Automation"
                          Label={{ label: "Marketing Automation" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Telecommunications"
                          Label={{ label: "Telecommunications" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="homesmart_interests"
                          value="Other"
                          Label={{ label: "Other (please specify)" }}
                          size="small"
                        />
                        {values.homesmart_interests.includes("Other") && (
                          <Field
                            id="interests_other"
                            name="interests_other"
                            label="Other"
                            component={TextField}
                            size="small"
                            autoFocus
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {fieldList.includes("solutions") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      Which solutions are you most interested in?
                    </span>
                    <Grid container spacing={1}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="solutions"
                          value="IDX Broker"
                          Label={{ label: "IDX Broker" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="solutions"
                          value="IXACT Contact"
                          Label={{ label: "IXACT Contact" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="solutions"
                          value="Morris Marketing Group"
                          Label={{ label: "Morris Marketing Group" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="solutions"
                          value="Aiva"
                          Label={{ label: "Aiva" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="solutions"
                          value="OutboundEngine"
                          Label={{ label: "OutboundEngine" }}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="solutions"
                          value="VoicePad"
                          Label={{ label: "VoicePad" }}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {fieldList.includes("idx_options") && (
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: "24px" }}>
                    <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                      I'm interested in a:
                    </span>
                    <Field component={RadioGroup} name="interests">
                      <FormControlLabel
                        value="Turn-Key Website"
                        control={<Radio disabled={isSubmitting} />}
                        label="Turn-Key Website"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Semi-Custom Website"
                        control={<Radio disabled={isSubmitting} />}
                        label="Semi-Custom Website"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Custom Website"
                        control={<Radio disabled={isSubmitting} />}
                        label="Custom Website"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Unsure, Let's Explore All!"
                        control={<Radio disabled={isSubmitting} />}
                        label="Unsure, Let's Explore All!"
                        disabled={isSubmitting}
                      />
                    </Field>
                  </Grid>
                )}
                {fieldList.includes("markets") && (
                  <>
                    <Grid item md={12} xs={12} style={{ marginTop: "24px" }}>
                      <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>Top 3 Markets:</span>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field
                        id="market1"
                        name="market1"
                        label="City/State"
                        component={TextField}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field
                        id="market2"
                        name="market2"
                        label="City/State"
                        component={TextField}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field
                        id="market3"
                        name="market3"
                        label="City/State"
                        component={TextField}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                {fieldList.includes("needs") && (
                  <>
                    <Grid item md={12} xs={12} style={{ marginTop: "24px" }}>
                      <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                        Description of Needs:
                      </span>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field id="notes" name="notes" label="Needs" component={TextField} size="small" fullWidth />
                    </Grid>
                  </>
                )}
                {fieldList.includes("accomplish") && (
                  <>
                    <Grid item md={12} xs={12} style={{ marginTop: "24px" }}>
                      <span style={{ fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                        Tell us what you'd like to accomplish:
                      </span>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Field id="notes" name="notes" label="" component={TextField} size="small" fullWidth />
                    </Grid>
                  </>
                )}
                {fieldList.includes("qualifying") && (
                  <>
                    <Grid item md={12} xs={12} style={{ marginTop: "24px" }}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="schedule_demo"
                        value="schedule_demo"
                        size="small"
                      />
                      <span style={{ marginLeft: "-14px", fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                        I'd like to schedule a demo
                      </span>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="existing_client"
                        value="existing_client"
                        size="small"
                      />
                      <span style={{ marginLeft: "-14px", fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                        I'm an existing client
                      </span>
                    </Grid>
                  </>
                )}

                {fieldList.includes("currentClient") && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="existing_client"
                        value="existing_client"
                        size="small"
                      />
                      <span style={{ marginLeft: "-14px", fontWeight: "600", color: mainTheme.palette.secondary.main }}>
                        I'm an existing client
                      </span>
                      <br />
                      {values.existing_client.includes("existing_client") && (
                        <Field
                          id="current_solutions"
                          name="current_solutions"
                          label="Current Solutions"
                          component={TextField}
                          size="small"
                          autoFocus
                        />
                      )}
                      <br />
                    </Grid>
                  </>
                )}

                <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{ width: "50%", backgroundColor: buttonColor + " !important" }}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>

              <div style={{ fontSize: "11px", textAlign: "center", marginTop: "36px" }}>
                By submitting this form, you are requesting to be contacted by a member of the Elm Street Technology
                team at the details provided via text, email or call (may involve automated or pre-recorded means). You
                may revoke this consent through any reasonable means.
              </div>
            </Container>
          </Form>
        )}
      />
    </ThemeProvider>
  );
};

export default DemoForm;
